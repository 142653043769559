<template>
  <div class="widgetContainer">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span
          v-if="$businessChecking === true && $personalChecking === true"
          class="icon-Arrow-big-left cancel"
          @click="drawerBack" />
        <span v-else>&nbsp;</span>
        <p class="title">
          Add Business
        </p>
      </div>
    </div>
    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="business"
        ref="businessForm"
        :rules="formRules"
        @submit.prevent.native
        class="wise-form">
        <el-form-item
          class="is-no-asterisk"
          prop="legalName"
          :label="$t('businessName')">
          <el-input v-model="business.legalName" />
        </el-form-item>

        <el-form-item class="is-no-asterisk" prop="dba" :label="$t('dbaName')">
          <el-input v-model="business.dba" type="dba" />
        </el-form-item>

        <el-form-item
          class="is-no-asterisk column-input"
          prop="entityType"
          :label="$t('entityType')">
          <el-select
            class="form-control1 role-select"
            required
            v-model="business.entityType">
            <el-option
              v-for="entityType in entityTypes"
              :key="entityType.key"
              :value="entityType.key"
              :label="entityType.label" />
          </el-select>
        </el-form-item>

        <el-form-item
          class="is-no-asterisk"
          required
          prop="idNumber"
          :label="$t('ein')">
          <EINInput v-model="business.idNumber" :autofocus="false" />
        </el-form-item>
        <p class="wise-form__info">
          {{ $t('ein_Message') }}
        </p>
        <div class="el-input--full-width el-input">
          <el-form-item
            prop="address.line1"
            :rules="formRules.line1"
            class="is-no-asterisk"
            :label="$t('address_1')">
            <el-input style="display: none" />
            <el-input v-model="business.address.line1" style="display: none" />
            <div class="el-input">
              <gmap-autocomplete
                :placeholder="$t('address_1')"
                autocomplete="disabled"
                :value="business.address.line1"
                :options="{
                  componentRestrictions: { country: country }
                }"
                @change.native="business.address.line1 = $event.target.value"
                @place_changed="updateAddress($event)"
                :select-first-on-enter="true"
                class="el-input__inner" />
            </div>
          </el-form-item>
        </div>
        <el-form-item prop="address.line2" :label="$t('address_2')">
          <el-input
            :placeholder="$t('address_2')"
            v-model="business.address.addressLine2" />
        </el-form-item>
        <div class="wise-form__inline">
          <el-form-item
            prop="address.city"
            class="is-no-asterisk"
            :rules="formRules.city"
            :label="$t('address_City')">
            <el-input
              style="flex: 2"
              :placeholder="$t('address_City')"
              v-model="business.address.city" />
          </el-form-item>
          <el-form-item
            prop="address.country"
            class="is-no-asterisk"
            :rules="formRules.state"
            :label="$t('address_State')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_State')"
              v-model="business.address.state" />
          </el-form-item>
          <el-form-item
            prop="address.postalCode"
            class="is-no-asterisk"
            :rules="formRules.postalCode"
            :label="$t('address_Zipcode')">
            <el-input
              style="flex: 1"
              :placeholder="$t('address_Zipcode')"
              v-model="business.address.postalCode" />
          </el-form-item>
        </div>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" class="el-button__brand brand" @click="submitForm()" :disabled="isFormValid">
          Save
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EINInput from '@/components/EINInput';
import {getCountryDetailsFromPhoneNumber} from '@/utils/env';
import {formatGoogleMapData} from '@/utils/gmap';
import {businessService} from '@/services/solidfi';
export default {
  name: 'AddBusiness',
  components: {
    EINInput
  },
  created() {
    /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType]}*/
  },
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('user', ['getPersonDetails']),
    ...mapGetters('account', ['getAllAccount']),
    country() {
      /** @type {import('@m/user/store/state').userState['personDetails']} */
      const person = this.getPersonDetails;
      const details = getCountryDetailsFromPhoneNumber(person.phone);
      if(details && details.code)
        return details.code;
      return 'US'
    },
    isFormValid() {
      if (
        this.business.legalName &&
          this.business.entityType &&
          this.business.idNumber &&
          this.business.idNumber.length === 9
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      /** @type {import('@m/kyb/store/state').kybState['selectedBusinessType]}*/
      business: {
        legalName: '',
        entityType: '',
        dba: '',
        idNumber: '',
        idType: 'ein',
        address: {
          line1: null,
          line2: null,
          city: null,
          postalCode: null,
          country: 'US',
          addressType: 'mailing',
          state: null
        }
      },
      formRules: {
        legalName: [
          {
            required: true,
            message: 'Legal Name is required'
          }
        ],
        entityType: [
          {
            required: true,
            message: 'Entity type is required'
          }
        ],
        idNumber: [
          {
            required: true,
            message: 'EIN is required'
          }
        ],
        line1: [
          {
            required: true,
            message: 'Address 1 is required'
          }
        ],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        postalCode: [
          {
            required: true,
            message: 'Zip Code is required'
          }
        ]
      },
      entityTypes: [
        { key: 'soleProprietor', label: 'Sole Proprietor' },
        { key: 'singleMemberLLC', label: 'Single Member LLC' },
        { key: 'limitedLiabilityCompany', label: 'Limited Liability Company' },
        { key: 'generalPartnership', label: 'General Partnership' },
        { key: 'unlistedCorporation', label: 'Corporation' },
        {
          key: 'publiclyTradedCorporation',
          label: 'Publicly Traded Corporation'
        },
        { key: 'association', label: 'Association' },
        { key: 'nonProfit', label: 'Non Profit' },
        { key: 'governmentOrganization', label: 'Government Organization' },
        { key: 'revocableTrust', label: 'Revocable Trust' },
        { key: 'irrevocableTrust', label: 'Irrevocable Trust' },
        { key: 'estate', label: 'estate' }

        /**
         * Not supported
         * @see {@link https://wise.atlassian.net/browse/WB-130}
         * {key : "professionalAssociation",label: "Professional Association"},
         * {key : "limitedPartnership",label: "Limited Partnership"},
         * {key : "limitedLiabilityPartnership",label: "Limited Liability Partnership"},
         * {key : "professionalCorporation",label: "Professional Corporation"},
         */
      ]
    };
  },
  methods: {
    updateAddress(e) {
      const address = formatGoogleMapData(e);
      this.business.address = address;
    },
    ...mapMutations(['updateLogoutPopupStatus']),
    ...mapActions('user', ['logout']),
    logoutAndRedirect() {
      this.updateLogoutPopupStatus(true);
    },
    ...mapActions('kyb', [
      'createBusiness',
      'updateBusiness',
      'listAllBusiness'
    ]),
    ...mapMutations('kyb', ['setSelectedBusiness']),
    submitForm() {
      this.$refs.businessForm.validate((valid) => {
        if (!valid) return;
        /** @type {import('@m/user/store/state').userState['personDetails']}*/
        const personDetails = this.getPersonDetails;
        const { line1, line2, city, postalCode, state } = this.business.address;
        this.business.address = {line1, line2, city, postalCode, state, country: this.country, addressType: 'legal'}
        let payload = {
          ...this.business,
          phone: personDetails.phone,
          email: personDetails.email
        };
        const loader = this.showLoader();
        this.createBusiness(payload)
          .then((business) => {
            return this.submitKyb(business.id)
              .then(() => {
                this.$notify({
                  title: 'Business Added Successfully!',
                  message: 'Please wait while we verify your business credentials',
                  type: 'success'
                });
                this.drawerBack()
              })
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      });
    },
    submitKyb(businessId) {
      return new Promise((resolve, reject) => {
        businessService.submitKYB(businessId, { businessId })
          .then(({ data }) => {
            this.$store.commit('kyb/setKybDetails', data);
            resolve(data);
          })
          .catch((e) => {
            if (e.response && e.response.data) {
              reject(e.response.data);
            } else {
              reject(e);
            }
          });
      });
    },
  }
};
</script>